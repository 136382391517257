
import React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from "@prismicio/react";
import Navbar from '../sections/Navbar/Navbar'
import Footer from '../sections/Footer'

const Montreal = ({ data }: any) => {
    const info = data.prismicSeoPageType.dataRaw

    return (
        <div>
            <Navbar />
            <div className='LegalDocs'>
                <div className='flex p-2'>
                    <p className='font-bold'>Region - </p>
                    <PrismicRichText field={info.region} />
                </div>

                <div className='flex p-2'>
                    <p className='font-bold'>Country - </p>
                    <PrismicRichText field={info.country} />
                </div>

                <div className='flex p-2'>
                    <p className='font-bold'>Population - </p>
                    <PrismicRichText field={info.population} />
                </div>

                <div className='flex p-2'>
                    <p className='font-bold'>Population Names - </p>
                    <PrismicRichText field={info.population_names} />
                </div>
                <div className='flex p-2'>
                    <p className='font-bold'> Number of coaching firms in the city - </p>
                    <PrismicRichText field={info.number_of_coaching_firms_in_the_city} />
                </div>
                <div className='p-2' >
                    <p className='font-bold'> Information city website -</p>
                    <PrismicRichText field={info.information_city_website} />
                </div>
                <div className='p-2'>
                    <p className='font-bold'> Coaching market website -</p>
                    <PrismicRichText field={info.coaching_market_website} />
                </div>
                <div className='flex p-2'>
                    <p className='font-bold'> Individual Coaching -</p>
                    <PrismicRichText field={info.individual_coaching} />
                </div>
                <div className='flex p-2'>
                    <p className='font-bold'> Group Coaching -</p>
                    <PrismicRichText field={info.group_coaching} />
                </div>
                <div className='flex p-2'>
                    <p className='font-bold'> Executive Coaching -</p>
                    <PrismicRichText field={info.executive_coaching} />
                </div>
                <div className='p-2'>
                    <p className='font-bold'> What criteria should you use to choose your professional coach -</p>
                    <PrismicRichText field={info.what_criteria_should_you_use_to_choose_your_professional_coach} />
                </div>
                <div className='p-2'>
                    <p className='font-bold'> What is the right price for a professional coach" -</p>
                    <PrismicRichText field={info.what_is_the_right_price_for_a_professional_coach} />
                </div>
                <div className='p-2'>
                    <p className='font-bold'> How many sessions do I need -</p>
                    <PrismicRichText field={info.how_many_sessions_do_i_need} />
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Montreal;

export const query = graphql`
query SeoDocs  {
    prismicSeoPageType(uid: { eq: "montreal" }, lang: { eq: "en-gb" }) {
        uid
        lang
        url
        dataRaw
    }
}
`;


